import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CacheKeys, QueryParams } from "../types";
import { useSearchParams } from "react-router-dom";
import { LocalStorage } from "../utils/localStorage";
import { FetchFunctionParams, PaginationResponse } from "../types/pagination";
const ID_PAGE = 99999999999999;

export const useCachedPagination = <T>(
  cacheId: string,
  perPage: number,
  fetchFunction: (v: FetchFunctionParams) => Promise<PaginationResponse<T>>,
  cacheName: CacheKeys,
  id?: string
) => {
  const abortController = useRef(new AbortController());
  const [data, setData] = useState<T[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [restart, setRestart] = useState<boolean>();
  const [searchParams, setSearchParams] = useSearchParams();
  const afterCursorId = useMemo(() => {
    return searchParams.get(cacheId + QueryParams.Id) || undefined;
  }, [searchParams, cacheId]);

  const getAfterCursors = useCallback(() => {
    const afterCursorsString = localStorage.getItem(
      CacheKeys.afterCursor + cacheId
    );
    if (afterCursorsString === null) return null;
    return JSON.parse(afterCursorsString);
  }, [cacheId]);

  const getNewAfterCursor = () => {
    let afterCursor = afterCursorId;
    let afterCursors = [];
    const afterCursorsString = localStorage.getItem(
      CacheKeys.afterCursor + cacheId
    );
    if (afterCursorsString !== null) {
      afterCursors = JSON.parse(afterCursorsString);
      afterCursor = afterCursors[page.current];
    }
    return afterCursor;
  };

  const pageNumber = useMemo(() => {
    const afterCursors = getAfterCursors();
    if (afterCursors !== null) {
      const index = afterCursors.indexOf(afterCursorId);
      const number = index !== -1 ? index : 0;
      return number;
    }
    if (!afterCursorId) return 0;
    setRestart(true);
    return ID_PAGE;
  }, [afterCursorId, getAfterCursors]);

  const page = useRef<number>(Number(pageNumber) || 0);
  const [isLastPage, setIsLast] = useState<boolean>(false);

  const updateLocalStorage = useCallback(
    (cursor: string | null, page: number) => {
      const afterCursorsString = localStorage.getItem(
        CacheKeys.afterCursor + cacheId
      );
      let afterCursors = [];
      if (afterCursorsString !== null) {
        afterCursors = JSON.parse(afterCursorsString);
      }
      afterCursors[page + 1] = cursor;
      LocalStorage.setUserData(
        CacheKeys.afterCursor + cacheId,
        JSON.stringify(afterCursors)
      );
    },
    [cacheId]
  );

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      const currentKey = cacheName + cacheId + pageNumber;
      if (event.key === currentKey && event.newValue) {
        setData(JSON.parse(event.newValue).items);
      }
    },
    [cacheId, cacheName, pageNumber]
  );

  const fetchData = useCallback(
    async ({
      afterCursor,
      cacheKey,
      page,
      restart,
    }: {
      afterCursor?: string;
      cacheKey: string;
      page: number;
      restart?: boolean;
    }) => {
      let fetchAborted = false;
      try {
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
          const data: PaginationResponse<T> = JSON.parse(cachedData);
          setIsLast(data.page.isLastPage);
          setData(data.items);
        } else {
          setLoading(true);
        }
        abortController.current.abort();
        const controller = new AbortController();
        abortController.current = controller;
        const response = await fetchFunction({
          perPage: perPage.toString(),
          afterCursor,
          id,
          signal: controller.signal,
        });
        setData(response.items);
        setIsLast(response.page.isLastPage);
        LocalStorage.setUserData(cacheKey, JSON.stringify(response));
        if (!restart) {
          updateLocalStorage(response.page.cursor, page);
        }
      } catch (e) {
        if ((e as any).code === 20) fetchAborted = true;
        console.info(e);
      } finally {
        if (!fetchAborted) setLoading(false);
      }
      window.addEventListener("storage", handleStorageChange);
      return () => {
        window.removeEventListener("storage", handleStorageChange);
      };
    },
    [id, perPage, updateLocalStorage, fetchFunction, handleStorageChange]
  );

  useEffect(() => {
    fetchData({
      cacheKey: cacheName + cacheId + pageNumber,
      restart,
      afterCursor: getNewAfterCursor(),
      page: page.current,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const handleIdParam = (page: number) => {
    setRestart(false);
    const afterCursors = getAfterCursors();
    if (afterCursors === null || !afterCursors[page]) {
      searchParams.delete(cacheId + QueryParams.Id);
      setSearchParams(searchParams);
      return;
    }
    const currentParams = new URLSearchParams(searchParams);
    currentParams.set(cacheId + QueryParams.Id, afterCursors[page]);
    setSearchParams(currentParams);
  };

  const handleNext = async () => {
    const nextPage = page.current + 1;
    const newPage = restart ? 0 : nextPage;
    const newCacheKey = cacheName + cacheId + newPage;
    page.current = newPage;
    handleIdParam(nextPage);
    await fetchData({
      cacheKey: newCacheKey,
      afterCursor: restart ? undefined : getNewAfterCursor(),
      page: page.current,
    });
  };

  const handlePrevious = async () => {
    const previousPage = page.current - 1;
    const newPage = restart ? 0 : previousPage;
    const newCacheKey = cacheName + cacheId + newPage;
    page.current = newPage;

    handleIdParam(previousPage);
    await fetchData({
      cacheKey: newCacheKey,
      afterCursor: restart ? undefined : getNewAfterCursor(),
      page: page.current,
    });
  };

  const isFirstPage = page.current === 0;
  return {
    data,
    loading,
    handleNext,
    handlePrevious,
    isFirstPage,
    isLastPage,
  };
};
