import { FlexAccount as FlexAccountResponse } from "../types";
import { CustomError } from "../types";

const getData = async (userId?: string | null) => {
  const path = `/flex-account${!!userId ? `?userId=${userId}` : ""}`;

  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (response.ok) {
    return res as FlexAccountResponse;
  }
  CustomError.throw(res, res?.error || res?.message);
};

const getInterest = async () => {
  const path = "/flex-account/interest";
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (response.ok) {
    return res as { name: string; value: string };
  }
  CustomError.throw(res, res?.error || res?.message);
};

const updateInterest = async (interest: string) => {
  const path = "/flex-account/interest";
  const response = await fetch(path, {
    method: "PUT",
    body: JSON.stringify({
      interest: Number(interest.replace(/,/g, "")),
    }),
  });
  if (!response.ok) {
    const res = await response.json();
    CustomError.throw(res, res?.error || res?.message);
  }
};

export const FlexAccount = {
  getData,
  getInterest,
  updateInterest,
};
