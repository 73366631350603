import { FormEvent } from "react";
import { FormInput } from "../../../components/forms/formsComponents/FormInput";
import { useForm } from "../../../hooks/useForm";
import { ResponseStatus } from "../../../types/ResponseStatus";
import {
  Auth,
  repeatPasswordValidation,
  validateAmazonPassword,
} from "../../../utils";
import { notifications } from "../../../utils/notifications";
import {
  changePasswordInitialData,
  changePasswordInitialErrors,
  changePasswordInitialTouch,
  securityInputTracker,
} from "../constants";
import FormWrapper from "../../../components/FormWrapper";
import { CHANGE_PASSWORD_CUSTOM_ERRORS } from "../types";

interface Props {
  isActive: boolean;
  disabledForm?: boolean;
}

const ChangePassword = ({ isActive, disabledForm }: Props) => {
  const {
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    fields,
    errors,
  } = useForm({
    initialFields: changePasswordInitialData,
    initialErrors: changePasswordInitialErrors,
    initialTouch: changePasswordInitialTouch,
    customErrors: CHANGE_PASSWORD_CUSTOM_ERRORS,
    inputTracker: securityInputTracker,
  });

  const onSubmitForm = async () => {
    if (!!disabledForm) return;
    try {
      await Auth.changePassword({
        currentPassword: fields.currentPassword,
        newPassword: fields.newPassword,
      });
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `The password was updated successfully`,
      });
    } catch (e: any) {
      console.log(e);
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || `Unexpected error updating the password`,
      });
      throw new Error("Something wrong happened updating the user password");
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    onSubmit(e, onSubmitForm);
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      loading={!!submitLoading}
      buttonMessage={submitLoading ? "Updating" : "Update"}
      title={"Security"}
      needQuestionMark={true}
      questionMarkMessage="To change your password, you must enter your current password."
      collapseKey="accountSecurityCollapsed"
      isActive={isActive}
      disabledForm={!!disabledForm}
    >
      <div className="row g-3 w-100">
        <div className="col-12">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            name="currentPassword"
            label="Current Password"
            value={fields.currentPassword}
            error={errors.currentPassword}
            required
            type="password"
            form={securityInputTracker}
            disabled={!!disabledForm}
          />
        </div>
        <div className="col-12">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            name="newPassword"
            label="New Password"
            value={fields.newPassword}
            error={errors.newPassword}
            validation={validateAmazonPassword}
            message="Please insert a valid password"
            required
            type="password"
            form={securityInputTracker}
            disabled={!!disabledForm}
          />
        </div>
        <div className="col-12">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            name="repeatPassword"
            label="Repeat Password"
            value={fields.repeatPassword}
            error={errors.repeatPassword}
            validation={(value: string) =>
              repeatPasswordValidation(value, fields.newPassword)
            }
            message="Passwords must be equal"
            required
            type="password"
            form={securityInputTracker}
            disabled={!!disabledForm}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default ChangePassword;
