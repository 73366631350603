import { FormEvent } from "react";
import { FormInput } from "../../../components/forms/formsComponents/FormInput";
import { FormSelect } from "../../../components/forms/formsComponents/FormSelect";
import { useForm } from "../../../hooks/useForm";
import states from "../../../models/States";
import { UserInitialData } from "../../../types/users";
import {
  initialErrors,
  initialTouch,
  personalFormInputTracker,
} from "../constants";
import FormWrapper from "../../../components/FormWrapper";
import { ACCOUNT_CUSTOM_ERRORS } from "../types";
import { handlePhoneChange, handlePhoneKeyDown } from "../../../utils";
interface Props {
  userInitialData: UserInitialData;
  updateUserData: (fields: UserInitialData) => Promise<void>;
  isActive: boolean;
  disabledForm?: boolean;
}

const PersonalForm = ({
  userInitialData,
  updateUserData,
  isActive,
  disabledForm,
}: Props) => {
  const {
    handleErrors,
    handleFields,
    handleTouch,
    submitLoading,
    onSubmit,
    fields,
    errors,
  } = useForm({
    initialFields: userInitialData,
    initialErrors: initialErrors,
    initialTouch: initialTouch,
    customErrors: ACCOUNT_CUSTOM_ERRORS,
    inputTracker: personalFormInputTracker,
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (!!disabledForm) return;
    onSubmit(e, () => updateUserData(fields));
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      loading={!!submitLoading}
      buttonMessage={submitLoading ? "Updating" : "Update"}
      title={"Personal"}
      collapseKey="accountPersonalInformationCollapsed"
      isActive={isActive}
      disabledForm={!!disabledForm}
    >
      <div className="row g-3 w-100">
        <div className="col-md-12">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            name="firstName"
            label="Name"
            value={fields.firstName}
            error={errors.firstName}
            disabled
            form={personalFormInputTracker}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={(phoneNumber) =>
              handlePhoneChange(phoneNumber, handleFields)
            }
            handleTouch={handleTouch}
            value={fields.phoneNumber}
            error={errors.phoneNumber}
            name="phoneNumber"
            label="Phone Number"
            form={personalFormInputTracker}
            onKeyDown={handlePhoneKeyDown}
            disabled={!!disabledForm}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            value={fields.email}
            error={errors.email}
            name="email"
            type="email"
            label="Email Address"
            disabled
            form={personalFormInputTracker}
          />
        </div>
        <div className="col-md-12">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            value={fields.addressLine1}
            error={errors.addressLine1}
            name="addressLine1"
            label="Address"
            form={personalFormInputTracker}
            disabled={!!disabledForm}
          />
        </div>
        <div className="col-md-12">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            value={fields.addressLine2}
            error={errors.addressLine2}
            name="addressLine2"
            label="Address 2"
            form={personalFormInputTracker}
            disabled={!!disabledForm}
          />
        </div>
        <div className="col-md-5">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            value={fields.city}
            error={errors.city}
            name="city"
            label="City"
            form={personalFormInputTracker}
            disabled={!!disabledForm}
          />
        </div>
        <div className="col-md-4">
          <FormSelect
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            value={fields.state}
            error={errors.state}
            defaultValue={fields.state}
            name="state"
            label="State"
            options={states}
            variant="floating"
            form={personalFormInputTracker}
            disabled={!!disabledForm}
          />
        </div>
        <div className="col-md-3">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            handleFields={handleFields}
            handleTouch={handleTouch}
            value={fields.zipCode}
            error={errors.zipCode}
            name="zipCode"
            label="Postal Code"
            form={personalFormInputTracker}
            disabled={!!disabledForm}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default PersonalForm;
