import { NavigateFunction } from "react-router-dom";
import { WebhookApiGatewayURI } from "../types/webhook-api-gateway";
import { Auth } from "./auth";
const MAINTENANCE_CODE = 503;
const COGNITO_URI = "https://cognito-idp.us-east-1.amazonaws.com/";
const COGNITO_ERROR_CODE = "NotAuthorizedException";

const originalFetch = window.fetch;

const add = (navigate: NavigateFunction) => {
  window.fetch = async (
    ...args: Parameters<typeof originalFetch>
  ): Promise<Response> => {
    const [resource, config] = args;
    if (!(resource as string).startsWith("/")) {
      const response = await originalFetch(resource, config);
      if (resource === COGNITO_URI && !response.ok) {
        const clone = response.clone();
        const cognitoResponse = await clone.json();
        if (cognitoResponse?.__type === COGNITO_ERROR_CODE) {
          Auth.signOut();
          navigate("/signin");
        }
      }
      return response;
    }
    let path: string = "";
    let options = { ...config };

    if (
      Object.values(WebhookApiGatewayURI).includes(
        resource as WebhookApiGatewayURI
      )
    ) {
      path = process.env.REACT_APP_BASE_PUBLIC_URL! + resource;
    } else {
      const token = await Auth.getUserToken();
      options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        ...options,
      };
      path = process.env.REACT_APP_BASE_URL! + resource;
    }
    const response = await originalFetch(path, options);
    if (response.status === MAINTENANCE_CODE) {
      await Auth.signOut();
      navigate("/maintenance");
    }
    return response;
  };
};

export const Interceptor = {
  add,
};
