import { useCallback } from "react";
import { Documents } from "../pages/documents/utils/documents";
import { CacheKeys } from "../types";
import { FetchFunctionParams } from "../types/pagination";
import { useCachedPagination } from "./useCachedPagination";

interface Props {
  cacheId: string;
  perPage: number;
  tag: string;
  id?: string;
  userId?: string;
}

export const useDocuments = ({ cacheId, perPage, tag, id, userId }: Props) => {
  const fetchFunction = useCallback(
    ({ perPage, afterCursor, id, signal }: FetchFunctionParams) =>
      Documents.getData({
        perPage,
        afterCursor,
        id,
        signal,
        tag,
        userId,
      }),
    [tag, userId]
  );
  const { data, loading, handleNext, handlePrevious, isFirstPage, isLastPage } =
    useCachedPagination(
      cacheId,
      perPage,
      fetchFunction,
      CacheKeys.documents,
      id
    );
  return {
    documents: data || [],
    loading,
    handleNext,
    handlePrevious,
    isFirstPage,
    isLastPage,
  };
};
