import styled from "styled-components";
import { Button as BoostrapButton, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

export const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton = styled(BoostrapButton)`
  width: 100%;
`;

export const AlertWithButton = styled(Alert)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const TextLink = styled.div`
  cursor: pointer;
  color: gray;
  font-size: 14px;
  text-decoration: underline;
`;

export const Ul = styled.ul`
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: white;
  font-weight: 500;
  font-size: 14px;
`;

export const Li = styled.li`
  font-size: 12px;
`;

interface ButtonProps {}

export const Button = styled.button<ButtonProps>`
  padding: 5px 25px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ theme, disabled }) =>
    disabled ? theme.GREEN : theme.PRIMARY_COLOR};
  background-color: ${({ theme }) => theme.BACKGROUND_COLOR};
  border-radius: 5px;
  font-size: 14px;
  border: none;
  font-weight: 700;
  border: solid 1px
    ${({ theme, disabled }) => (disabled ? theme.GREEN : theme.PRIMARY_COLOR)};
  :hover {
    color: ${({ theme }) => theme.GREEN};
    border-color: ${({ theme }) => theme.GREEN};
  }
`;

export const GreenButton = styled(Button)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ theme }) => theme.SECONDARY_COLOR};
  background-color: ${({ theme }) => theme.GREEN};
  border-color: ${({ theme }) => theme.GREEN};
  :hover {
    color: ${({ theme }) => theme.SECONDARY_COLOR};
  }
`;

export const CenteredNumber = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.PRIMARY_COLOR};
  background-color: ${({ theme }) => theme.BACKGROUND_COLOR};
  font-weight: 600;
  --bs-badge-padding-x: 0.75em;
  --bs-badge-padding-y: 0.45em;
`;

export const ProductText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 22px;
  color: #bebebe;
`;

export const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 22px;
  color: #bebebe;
`;

export const ViewAllPagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const FormButton = styled(Button)`
  @media (max-width: 380px) {
    font-size: 11px;
    padding: 3px 20px;
  }
`;

export const VerifyButton = styled(GreenButton)`
  padding: 5px 29px;
  @media (max-width: 400px) {
    font-size: 13px;
    padding: 3px 22px;
  }
  @media (max-width: 380px) {
    font-size: 11px;
    padding: 3px 24px;
  }
  @media (max-width: 320px) {
    font-size: 10px;
    padding: 3px 18px;
  }
`;

export const GreenLink = styled(Link)`
  color: ${({ theme }) => theme.GREEN};
`;

export const Hr = styled.hr`
  color: ${({ theme }) => theme.LIST_BORDER_COLOR};
`;
