import { CurrentBalance, CustomError } from "../types";

const getData = async (userId?: string | null) => {
  const path = `/total-balance${!!userId ? `?userId=${userId}` : ""}`;
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (response.ok) {
    return res as CurrentBalance;
  }
  CustomError.throw(res, res?.error || res?.message);
};

export const Balance = {
  getData,
};
