import { useCallback } from "react";
import { CacheKeys } from "../types";
import { Balance } from "../utils/balance";
import { useFetch } from "./useFetch";
import { useSearchParams } from "react-router-dom";

export const useBalance = () => {
  const [searchParams] = useSearchParams();
  const fetchFunction = useCallback(async () => {
    const response = await Balance.getData(searchParams.get("adminViewAs"));
    return response;
  }, [searchParams]);

  const { data, loading } = useFetch({
    cacheKey: CacheKeys.balance,
    fetchFunction,
  });
  return { data, loading };
};
