import { CustomError } from "../types";
import { BeneficiariesData, BeneficiariesInput } from "../types/beneficiaries";

const getBeneficiaries = async (userId?: string | null) => {
  const path = `/beneficiaries${userId ? `?userId=${userId}` : ""}`;
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as BeneficiariesData[];
};
const createBeneficiaries = async (input: BeneficiariesInput) => {
  const path = `/beneficiaries`;
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify(input),
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as BeneficiariesData[];
};
const deleteBeneficiaries = async (id: string) => {
  const path = `/beneficiaries/${id}`;
  const response = await fetch(path, {
    method: "PATCH",
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as BeneficiariesData[];
};

export const Beneficiaries = {
  getBeneficiaries,
  createBeneficiaries,
  deleteBeneficiaries,
};
