import { CustomError } from "../../../types";
import {
  CreateS3SignedURLControllerOutput,
  GetUserDocumentUrl,
  DocumentsResponse,
} from "../../../types/documents";

interface Props {
  perPage?: string;
  afterCursor?: string;
  id?: string;
  signal?: AbortSignal;
  tag?: string;
  userId?: string;
}

const getData = async ({
  id,
  perPage,
  afterCursor,
  signal,
  tag,
  userId,
}: Props) => {
  console.log({ userId });
  let path = `/user-document/${id ? `/${id}` : ""}${
    perPage ? `?perPage=${perPage}` : ""
  }${afterCursor ? `&afterCursor=${afterCursor}` : ""} ${
    userId ? `&userId=${userId}` : ""
  }`;
  if (tag) path += `&tag=${tag}`;
  const response = await fetch(path, {
    method: "GET",
    signal: signal || null,
  });
  const res = await response.json();
  if (!response.ok) {
    CustomError.throw(res, res?.error || res?.message);
  }
  return res as DocumentsResponse;
};

const getUrl = async (input: GetUserDocumentUrl) => {
  const path = "/signed-url";
  const response = await fetch(path, {
    method: "POST",
    body: JSON.stringify(input),
  });
  const res = await response.json();
  if (response.ok) {
    return res as CreateS3SignedURLControllerOutput;
  }
  CustomError.throw(res, res?.error || res?.message);
};

export const Documents = {
  getData,
  getUrl,
};
