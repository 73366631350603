import { useEffect, useRef } from "react";
import { Interceptor as Intercept } from "../utils";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  children: JSX.Element;
}

export const Interceptor = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const prevAdminViewAs = useRef<string | null>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentAdminViewAs = searchParams.get("adminViewAs");

    if (currentAdminViewAs) {
      prevAdminViewAs.current = currentAdminViewAs;
      return;
    }

    if (prevAdminViewAs.current) {
      searchParams.set("adminViewAs", prevAdminViewAs.current);
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [location.pathname, location.search, navigate]);

  Intercept.add(navigate);

  return <>{children}</>;
};
