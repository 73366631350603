import { FormEvent } from "react";
import { FormInput } from "../../../components/forms/formsComponents/FormInput";
import { useForm } from "../../../hooks/useForm";
import { ResponseStatus } from "../../../types/ResponseStatus";
import { notifications } from "../../../utils/notifications";
import {
  financialInitialErrors,
  financialInitialTouch,
  financialFormInputTracker,
} from "../constants";
import FormWrapper from "../../../components/FormWrapper";
import { CHANGE_PASSWORD_CUSTOM_ERRORS } from "../types";
import { Auth, Payments, handleCurrencyKeyDown } from "../../../utils";
import { CustomCognitoAttributes } from "../../../types/cognito-attributes";
import styled from "styled-components";
import { CustomSwitch, Slider, StyledInput } from "../../../styles/switch";

interface Props {
  initialData: { income: string; accredited: string; netAssets: string };
  updateNetAssets: (netAssets: string) => Promise<void>;
  isActive: boolean;
  disabledForm?: boolean;
}

const Financials = ({
  initialData,
  updateNetAssets,
  isActive,
  disabledForm,
}: Props) => {
  const {
    handleErrors,
    handleInputChange,
    handleTouch,
    submitLoading,
    onSubmit,
    fields,
    errors,
    setFields,
    touch,
    setTouch,
    resetTouch,
  } = useForm({
    initialFields: initialData,
    initialErrors: financialInitialErrors,
    initialTouch: financialInitialTouch,
    customErrors: CHANGE_PASSWORD_CUSTOM_ERRORS,
    inputTracker: financialFormInputTracker,
  });

  const onSubmitForm = async () => {
    try {
      const promises: Promise<any>[] = [];
      if (touch.income)
        promises.push(
          Auth.updateAttributes(
            CustomCognitoAttributes.income,
            Payments.convertToCents(fields.income)
          )
        );
      if (touch.accredited)
        promises.push(
          Auth.updateAttributes(
            CustomCognitoAttributes.accredited_investor,
            fields.accredited
          )
        );
      if (touch.netAssets) {
        promises.push(updateNetAssets(fields.netAssets));
      }
      await Promise.all(promises);
      resetTouch();
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `The financial information was updated successfully`,
      });
    } catch (e: any) {
      console.log(e);
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage:
          e?.message || `Unexpected error updating the financial information`,
      });
      throw new Error(
        "Something wrong happened updating the user financial information"
      );
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (!!disabledForm) return;
    onSubmit(e, onSubmitForm);
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!disabledForm) return;
    const checked = e.target.checked;
    setTouch({ ...touch, accredited: true });
    setFields({ ...fields, accredited: checked.toString() });
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      loading={!!submitLoading}
      buttonMessage={submitLoading ? "Updating" : "Update"}
      title={"Financials"}
      collapseKey="accountFinancialsCollapsed"
      isActive={isActive}
      disabledForm={!!disabledForm}
    >
      <div className="row g-3 w-100">
        <div className="col-md-6">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            onChange={handleInputChange}
            handleTouch={handleTouch}
            name="income"
            label="Income"
            value={fields.income}
            error={errors.income}
            form={financialFormInputTracker}
            onKeyDown={handleCurrencyKeyDown}
            isMoney
            disabled={!!disabledForm}
          />
        </div>
        <div className="col-md-6 order-md-3">
          <FormInput
            variant="floating"
            handleErrors={handleErrors}
            onChange={handleInputChange}
            handleTouch={handleTouch}
            name="netAssets"
            label="Net Assets"
            value={fields.netAssets}
            error={errors.netAssets}
            form={financialFormInputTracker}
            onKeyDown={handleCurrencyKeyDown}
            isMoney
            disabled={!!disabledForm}
          />
        </div>
        <div className="col-md-6 order-md-2">
          <InputBox className="form-control">
            <label
              className="custom-control-label"
              htmlFor="accreditedInvestor"
            >
              Accredited investor
            </label>
            <CustomSwitch>
              <StyledInput
                id="accreditedInvestor"
                checked={fields.accredited === "true"}
                onChange={handleSwitchChange}
              />
              <Slider />
            </CustomSwitch>
          </InputBox>
          <StyledP>
            To be an accredited an investor there are specific requirements that
            need to be met. Visit the SEC website to{" "}
            <StyledAnchor
              target="_blank"
              rel="noreferrer"
              href="https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor"
            >
              learn more
            </StyledAnchor>
            .
          </StyledP>
        </div>
      </div>
    </FormWrapper>
  );
};

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.GREEN};
`;

const InputBox = styled.div`
  background-color: ${({ theme }) => theme.INPUT_BACKGROUND};
  color: ${({ theme }) => theme.INPUT_COLOR};
  border-color: ${({ theme }) => theme.INPUT_BORDER_COLOR};
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledP = styled.p`
  height: 0px;
  padding: 0px 5px;
  margin: 0;
  color: grey;
  font-size: 12px;
  line-height: normal;
  @media (max-width: 767px) {
    height: auto;
  }
`;

export default Financials;
