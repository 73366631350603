import { useCallback } from "react";
import { CacheKeys } from "../types";
import { FlexAccount } from "../utils";
import { useFetch } from "./useFetch";
import { useSearchParams } from "react-router-dom";

export const useFlexAccount = () => {
  const [searchParams] = useSearchParams();
  const fetchFunction = useCallback(async () => {
    const response = await FlexAccount.getData(searchParams.get("adminViewAs"));
    return response;
  }, [searchParams]);

  const { data, loading, refetchData } = useFetch({
    cacheKey: CacheKeys.flexAccount,
    fetchFunction,
  });
  return { data, loading, refetchData };
};
