import { useCallback } from "react";
import { CacheKeys } from "../types";
import { User } from "../utils";
import { useFetch } from "./useFetch";
import { useSearchParams } from "react-router-dom";

export const useUserSettings = () => {
  const [searchParams] = useSearchParams();
  const fetchFunction = useCallback(async () => {
    const response = await User.getUserSettings(
      searchParams.get("adminViewAs")
    );
    return response;
  }, [searchParams]);

  const { data, loading, refetchData } = useFetch({
    cacheKey: CacheKeys.userData,
    fetchFunction,
  });

  return {
    loading,
    data,
    refetchData,
  };
};
