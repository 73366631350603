import { useCallback } from "react";
import { CacheKeys } from "../types";
import { User } from "../utils";
import { useFetch } from "./useFetch";
import { useSearchParams } from "react-router-dom";

export const useNetAssets = () => {
  const [searchParams] = useSearchParams();
  const fetchFunction = useCallback(async () => {
    const response = await User.getNetAssets(searchParams.get("adminViewAs"));
    return response;
  }, [searchParams]);

  const { data, loading, refreshData } = useFetch({
    cacheKey: CacheKeys.netAssets,
    fetchFunction,
  });

  const updateNetAssets = async (netAssets: string) => {
    try {
      await User.updateNetAssets(netAssets);
      refreshData({ netAssets: Number(netAssets) });
    } catch (e: any) {
      throw new Error("Something wrong happened");
    }
  };

  return { data, loading, updateNetAssets };
};
