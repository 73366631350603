import { useCallback } from "react";
import { CacheKeys } from "../types";
import { useFetch } from "./useFetch";
import { Dashboard } from "../utils/dasboard";
import { useSearchParams } from "react-router-dom";

export const useDashboardInfo = () => {
  const [searchParams] = useSearchParams();
  const fetchFunction = useCallback(async () => {
    const response = await Dashboard.getData(searchParams.get("adminViewAs"));
    return response && Dashboard.mapData(response);
  }, [searchParams]);

  const { data, loading } = useFetch({
    cacheKey: CacheKeys.dashboardInfo,
    fetchFunction,
  });

  return { data, loading };
};
