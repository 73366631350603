import { useCallback } from "react";
import { CacheKeys } from "../types";
import { PromissoryNotes } from "../utils";
import { useFetch } from "./useFetch";
import { useSearchParams } from "react-router-dom";

export const usePromissoryNotes = () => {
  const [searchParams] = useSearchParams();
  const fetchFunction = useCallback(async () => {
    const response = await PromissoryNotes.getData(
      searchParams.get("adminViewAs")
    );
    return response;
  }, [searchParams]);

  const { data, loading } = useFetch({
    cacheKey: CacheKeys.promissoryNotes,
    fetchFunction,
  });
  return { data, loading };
};
