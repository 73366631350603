import StartBanner from "./components/StartBanner";
import { NorhartWrapper } from "../../components/NorhartWrapper";
import InvestBanner from "./components/InvestBanner";
// import FlexAccountList from "./FlexAccountList";
import DashboardAccount from "./components/DashboardAccount";
import PromissoryNoteList from "../../components/PromissoryNoteList";
import TransactionsList from "../../components/TransactionsList";
import { Button } from "../../styles";
import BankBanner from "../../components/BankBanner";
import WithdrawModal from "../../components/withdraw/WithdrawModal";
import AddMoneyModal from "../../components/addMoney/AddMoneyModal";
import { useDashboard } from "../../hooks/useDashboard";
import { Loading } from "../../components/Loading";
// import { CustomConfetti } from "../../components/Confetti";
import BankPendingAlerts from "../../components/BankPendingAlerts";
import { WithdrawAlert } from "./components/WithdrawAlert";
import { Wrapper, InvestButton } from "./styles";
import InvestData from "./components/InvestData";
import DepositModal from "../../components/deposit/DespositModal";
import { useSearchParams } from "react-router-dom";
// import { WithdrawAlert } from "./components/WithdrawAlert";

const Home = () => {
  const {
    flexAccount,
    loadingFlexAccount,
    promissoryNotes,
    loadingPromissoryNotes,
    transactions,
    loadingTransactions,
    showBankPopUp,
    handleBankClose,
    balance,
    loadingBalance,
    handleBannerClose,
    showStartBanner,
    loadingHasBankAccount,
    userData,
    userDataLoading,
    handleWithdrawClose,
    handleAddMoneyClose,
    openWithdrawFlow,
    handleAddMoneyClick,
    handleWithdrawClick,
    showWithdraw,
    showAddMoney,
    isAddMoney,
    handleStartNow,
    addMoneyNeedDefaultValue,
    loadingProducts,
    defaultProduct,
    waitingBankAccounts,
    openPlaidPopUp,
    ready,
    handleWithdrawAlert,
    withdrawAlert,
    attentionData,
    loadingAttentionData,
    dashboardInfo,
    loadingDashboardInfo,
    showDeposit,
    handleShowDeposit,
  } = useDashboard();
  const [searchParams] = useSearchParams();

  if (
    loadingFlexAccount ||
    loadingPromissoryNotes ||
    loadingTransactions ||
    loadingBalance ||
    !balance ||
    !flexAccount ||
    loadingHasBankAccount ||
    userDataLoading ||
    loadingProducts ||
    loadingAttentionData ||
    loadingDashboardInfo
  ) {
    return <Loading />;
  }
  return (
    <NorhartWrapper title="Dashboard">
      <Wrapper>
        {/* <CustomConfetti duration={10000} /> */}
        {!!attentionData && (
          <BankPendingAlerts
            bankAccountsPendingVerification={attentionData.bankAccounts.subject}
          />
        )}
        {showBankPopUp && (
          <BankBanner
            onClose={handleBankClose}
            ready={ready}
            waitingBankAccounts={waitingBankAccounts}
            handleClick={openPlaidPopUp}
          />
        )}
        {showStartBanner && (
          <StartBanner
            onClose={handleBannerClose}
            openAddMoney={handleStartNow}
            userData={userData}
            interest={defaultProduct?.interest}
            ready={ready}
            openPlaidPopUp={openPlaidPopUp}
          />
        )}
        {dashboardInfo && (
          <InvestBanner
            balance={balance.posted}
            latestMonthAmounts={dashboardInfo.totalInterestsPerMonth}
          />
        )}
        {dashboardInfo && <InvestData data={dashboardInfo} />}
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <Button
            disabled={!!searchParams.get("adminViewAs")}
            onClick={handleWithdrawClick}
          >
            Withdraw
          </Button>
          {/* <Button
            color="black"
            backgroundColor="#e6e6e5"
            onClick={handleWithdrawClick}
            data-cy="withdraw"
          >
            Withdraw
          </Button> */}
          <InvestButton
            onClick={handleAddMoneyClick}
            disabled={!!searchParams.get("adminViewAs")}
            data-cy="add-money"
          >
            Invest
          </InvestButton>
        </div>
        {showWithdraw && (
          <WithdrawModal
            startFlow={showWithdraw}
            onClose={handleWithdrawClose}
            ready={ready}
            waitingBankAccounts={waitingBankAccounts}
            openPlaidPopUp={openPlaidPopUp}
          />
        )}
        {showAddMoney && !showWithdraw && (
          <AddMoneyModal
            startFlow={showAddMoney}
            onClose={handleAddMoneyClose}
            isAddMoney={isAddMoney}
            needDefaultValue={addMoneyNeedDefaultValue}
            ready={ready}
            waitingBankAccounts={waitingBankAccounts}
            openPlaidPopUp={openPlaidPopUp}
          />
        )}
        {showDeposit && !showWithdraw && !showAddMoney && (
          <DepositModal
            startFlow={showDeposit}
            onClose={handleShowDeposit}
            ready={ready}
            waitingBankAccounts={waitingBankAccounts}
            openPlaidPopUp={openPlaidPopUp}
          />
        )}
        <WithdrawAlert onClose={handleWithdrawAlert} show={withdrawAlert} />
        {flexAccount && (
          <DashboardAccount
            openDeposit={handleShowDeposit}
            homepage
            currentBalance={flexAccount.currentBalance.posted}
          />
        )}
        {!!promissoryNotes?.length && (
          <PromissoryNoteList
            homepage
            promissoryNotes={promissoryNotes}
            title="Promissory Note"
            active
            plusButtonClick={openWithdrawFlow}
          />
        )}
        {transactions && (
          <TransactionsList homepage transactions={transactions.slice(0, 5)} />
        )}
      </Wrapper>
    </NorhartWrapper>
  );
};

export default Home;
