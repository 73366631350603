import { CustomError, PromissoryNoteAccount } from "../types";

const getData = async (userId?: string | null) => {
  const path = `/promissory-notes${userId ? `?userId=${userId}` : ""}`;
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (response.ok) {
    return res as PromissoryNoteAccount[];
  }
  CustomError.throw(res, res?.error || res?.message);
};

const getDataById = async (id: string) => {
  const path = `/promissory-notes/${id}`;
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (response.ok) {
    return res as PromissoryNoteAccount;
  }
  CustomError.throw(res, res?.error || res?.message);
};

const update = async ({
  id,
  autoReInvest,
}: {
  id: string;
  autoReInvest: boolean;
}) => {
  const path = `/promissory-notes`;
  const response = await fetch(path, {
    method: "PATCH",
    body: JSON.stringify({ id, autoReInvest }),
  });
  const res = await response.json();
  if (response.ok) {
    return res as PromissoryNoteAccount;
  }
  CustomError.throw(res, res?.error || res?.message);
};

export const PromissoryNotes = {
  getData,
  getDataById,
  update,
};
