import { useState, useCallback, useEffect } from "react";
import { Attention } from "../utils/attention";
import { GetAttentionDataOutput } from "../types/attention";
import { useSearchParams } from "react-router-dom";

export const useGetAttention = () => {
  const [attentionData, setAttentionData] = useState<GetAttentionDataOutput>();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const getAttentionData = useCallback(async () => {
    try {
      const response = await Attention.getData(searchParams.get("adminViewAs"));
      setAttentionData(response);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  }, [searchParams]);

  useEffect(() => {
    getAttentionData();
  }, [getAttentionData]);
  return { loading, attentionData };
};
