import { BankAccountVerificationStatus } from "../../../types";
import { Plaid, PlaidBankAccount } from "../../../utils";
import { useMemo, useState } from "react";
// import { TooltipDisabledButton } from "../../../components/TooltipDisabledButton";
import VerifyButton from "./VerifyButton";
import { SmallDot } from "../../../styles/list";
import { LeftWrapper, BankName, DeleteButton, Text, DeleteRow } from "./styles";

interface Props {
  id: string;
  bankName: string;
  last4: string;
  canDelete: boolean;
  status: BankAccountVerificationStatus;
  handleDelete: (v: string) => Promise<void>;
  linkToken?: string;
  disabledForm?: boolean;
}

const BankInformationRow = ({
  id,
  bankName,
  last4,
  status,
  handleDelete,
  canDelete,
  linkToken,
  disabledForm,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState<boolean>();

  const handleClick = async (id: string) => {
    setIsDeleting(true);
    await handleDelete(id);
    setIsDeleting(false);
  };

  const isPending = useMemo(() => Plaid.bankAccountIsPending(status), [status]);
  const {
    PendingManualVerification,
    // PendingAutomaticVerification,
    // PendingLoginRequired,
  } = BankAccountVerificationStatus;

  const color = PlaidBankAccount.handleStatus(status);
  return (
    <DeleteRow key={id}>
      <LeftWrapper>
        <SmallDot displaydot="true" color={color} />
        <Text>
          <BankName>
            {bankName} (*{last4})
          </BankName>
        </Text>
      </LeftWrapper>
      {canDelete && !isPending && (
        <DeleteButton
          onClick={() => handleClick(id)}
          disabled={isDeleting || disabledForm}
        >
          {isDeleting ? "Deleting" : "Delete"}
        </DeleteButton>
      )}
      {status === PendingManualVerification && linkToken && (
        <VerifyButton
          linkToken={linkToken}
          id={id}
          handleDelete={async () => await handleClick(id)}
        />
      )}
      {/* {([PendingAutomaticVerification, PendingLoginRequired].includes(status) ||
        !canDelete) &&
        ![PendingManualVerification].includes(status) && (
          <TooltipDisabledButton
            message={
              [PendingAutomaticVerification, PendingLoginRequired].includes(
                status
              )
                ? "Pending bank accounts can't be deleted"
                : "You must have at least one active bank account to use Norhart Invest"
            }
          />
        )} */}
    </DeleteRow>
  );
};

export default BankInformationRow;
