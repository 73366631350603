import AutomatedMicroDeposit from "../components/bankAccountsAlerts/AutomatedMicroDeposit";
import ManualMicroDeposit from "../components/bankAccountsAlerts/ManualMicroDeposit";
import ResetLogin from "../components/bankAccountsAlerts/ResetLogin";
import PendingExpiration from "../components/bankAccountsAlerts/PendingExpiration";
import { CustomError, PlaidAccountDetails } from "../types";
import {
  AlertComponentConstructor,
  AlertTypes,
  BankAccountsPendingVerification,
  BankPendingAlert,
  CustomPlaidAccountDetails,
  GetAttentionDataOutput,
} from "../types/attention";

const getData = async (userId?: string | null) => {
  const path = `/attention${userId ? `?userId=${userId}` : ""}`;
  const response = await fetch(path, {
    method: "GET",
  });
  const res = await response.json();
  if (response.ok) {
    return res as GetAttentionDataOutput;
  }
  CustomError.throw(res, res?.error || res?.message);
};

const mapping = (
  bankAccountsPendingVerification: BankAccountsPendingVerification
) => {
  return Object.entries(bankAccountsPendingVerification).map(
    ([key, value]: [
      string,
      BankPendingAlert<
        CustomPlaidAccountDetails | { accounts: PlaidAccountDetails[] }
      >
    ]) => {
      const returnValue = Object.values(value.accountsGrouping);
      return { [key]: returnValue };
    }
  );
};

const alertComponentMap: Record<AlertTypes, AlertComponentConstructor> = {
  [AlertTypes.pendingAutomaticMicroDeposit]: AutomatedMicroDeposit,
  [AlertTypes.pendingLoginRequired]: ResetLogin,
  [AlertTypes.pendingManualMicroDeposit]: ManualMicroDeposit,
  [AlertTypes.pendingExpiration]: PendingExpiration,
};

export const Attention = {
  getData,
  mapping,
  alertComponentMap,
};
