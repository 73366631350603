import { useCallback } from "react";
import { CacheKeys } from "../types";
import { ResponseStatus } from "../types/ResponseStatus";
import { UpdateUserBody, UserInitialData } from "../types/users";
import { User } from "../utils";
import { notifications } from "../utils/notifications";
import { useFetch } from "./useFetch";
import { useSearchParams } from "react-router-dom";

export const useUserData = () => {
  const [searchParams] = useSearchParams();
  const fetchFunction = useCallback(async () => {
    const response = await User.getData(searchParams.get("adminViewAs"));
    return response;
  }, [searchParams]);

  const { data, loading, refreshData, refetchData } = useFetch({
    cacheKey: CacheKeys.userData,
    fetchFunction,
  });

  const updateUserData = async (fields: UserInitialData) => {
    const input = new UpdateUserBody(fields);
    try {
      const response = await User.updateData(input);
      refreshData(response);
      notifications({
        status: ResponseStatus.SUCCESS,
        successMessage: `The user was updated successfully`,
      });
    } catch (e: any) {
      notifications({
        status: ResponseStatus.ERROR,
        errorMessage: e?.message || `Unexpected error updating the user`,
      });
      throw new Error("Something wrong happened");
    }
  };

  return {
    loading,
    userInitialData: data && new UserInitialData(data),
    data,
    refetchData,
    updateUserData,
  };
};
